import 'ni-ui/styles';
import 'ni-ui/styles.css';
import 'url-search-params-polyfill';
import React, { useMemo } from 'react';
import ReactDOM from 'react-dom';
import { retrieveQueryParams } from './common/utils';
import AppContextProvider from './app-context';
import PaymentPageApp from './paymentPageApp';
import PrePaymentPageApp from './PrepaymentPageApp';

const AppComponent = () => {
  const queryParams = useMemo(() => retrieveQueryParams(), []);

  const { pathname } = document.location;
  const threeDsIframeRedirect = queryParams.threeDSCompInd || queryParams.base64EncodedCRes;
  if (threeDsIframeRedirect) {
    return null;
  }
  return (
    <AppContextProvider initialState={{ code: queryParams.code, slim: queryParams.slim }}>
      {pathname === '/one-link' || pathname === '/static-link' ? (
        <PrePaymentPageApp />
      ) : (
        <PaymentPageApp />
      )}
    </AppContextProvider>
  );
};

ReactDOM.render(<AppComponent />, document.getElementById('root'));
