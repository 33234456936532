import React, { useState, useMemo, useCallback } from 'react';
import { node, shape } from 'prop-types';

const AppContext = React.createContext();
const initialEmptyState = {
  outletRef: null,
  orderRef: null,
  orderDetails: null,
  selectedCurrency: null,
  refetchOrder: null,
  slim: 0,
  code: null,
  languageDirection: 'ltr',
  installmentDetails: null,
  selectedInstallmentOption: null,
  hasSelectedInstallmentOption: false,
  installmentSpinner: false,
  panOrCardToken: '',
  loader: {
    state: 'INITIAL_LOAD',
    message: 'INITIAL_LOADER_TEXT'
  },
  currentSplitPaymentCard: 0,
  currentSelectedPaymentMethod : ''
};

const AppContextProvider = ({ children, initialState }) => {
  const [state, setHookState] = useState({ ...initialEmptyState, ...initialState });
  const setContextState = useCallback(data =>
    setHookState(oldState => ({ ...oldState, ...data })), []);
  const contextValue = useMemo(() => ({ ...state, setContextState, setHookState }), [state]);

  return (
    <AppContext.Provider value={contextValue}>
      {children}
    </AppContext.Provider>
  );
};

AppContextProvider.propTypes = {
  children: node.isRequired,
  initialState: shape({}),
};

AppContextProvider.defaultProps = {
  initialState: initialEmptyState,
};

export { AppContext };
export default AppContextProvider;
