import React from 'react';

import { Row, Col } from 'ni-ui/layout';
import DateTime from 'ni-ui/date-time';
import Text, { TextTypes } from 'ni-ui/text';
import Hr from 'ni-ui/hr';

import PropTypes from 'prop-types';

import styles from './styles.scss';


function RecurringDetails({ order }) {
  return (
    <div style={{ marginTop: '20px' }} className={styles.inheritWidth}>
      <Row>
        <Col>
          <div className={styles.recurringPaymentHeader}>
            <Text type={TextTypes.H3} textKey="RECURRING_DETAILS_HEADER" />
            <Hr />
          </div>
          <Row>
            <Col span={12}>
              <div className={styles.recurringDetailsContainer}>
                <div className={styles.singleRecurringItem}>
                  <Text type={TextTypes.LABEL_LARGE} textKey="START_DATE" />
                  <div className={styles.verticalPadding}>
                    <DateTime value={order?.recurringDetails?.startDate} format="DD/MM/YYYY" />
                  </div>
                </div>

                <div className={styles.singleRecurringItem} >
                  <Text type={TextTypes.LABEL_LARGE} textKey="END_DATE" />
                  <div className={styles.verticalPadding}>
                    {order.recurringDetails.endDate ?
                      <DateTime value={order?.recurringDetails?.endDate} format="DD/MM/YYYY" />
                      :
                      <Text type={TextTypes.BODY} textKey="N/A" disableI18n />
                    }
                  </div>
                </div>

                <div className={styles.singleRecurringItem}>
                  <Text type={TextTypes.LABEL_LARGE} textKey="RECURRING_AMOUNT" />
                  <div className={styles.verticalPadding}>
                    <Text
                      type={TextTypes.BODY}
                      textKey={`${order?.amount?.currencyCode} ${order?.recurringDetails?.recurringAmount?.formattedValue}`}
                      disableI18n
                    />
                  </div>
                </div>

                <div className={styles.singleRecurringItem}>
                  <Text type={TextTypes.LABEL_LARGE} textKey="FREQUENCY" />
                  <div className={styles.verticalPadding}>
                    <Text
                      type={TextTypes.BODY}
                      textKey={
                        order?.frequency?.charAt(0).toUpperCase()
                        + order?.frequency?.slice(1).toLowerCase()}
                      disableI18n
                    />
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
}

RecurringDetails.propTypes = {
  order: PropTypes.shape({}).isRequired
};
export default RecurringDetails;
